//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('global', ['pageHasModalOpen', 'isMobileMenuOpen', 'mainNav', 'logo', 'footer']),
    hasOpenMenu() {
      return this.pageHasModalOpen || this.isMobileMenuOpen
    },
  },
}
