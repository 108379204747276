//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { Portal } from 'portal-vue'

export default {
  components: {
    Portal,
  },
  props: {
    nav: {
      type: Array,
      required: true,
    },
    logo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('global', ['isMobileMenuOpen', 'pageHasModalOpen']),
    isHomePage() {
      return this.$route.fullPath === '/' || this.$route.fullPath === '/home'
    },
  },
  watch: {
    $route(to, from) {
      this.closeMenu()
    },
  },
  methods: {
    async toggleMobileMenu() {
      await this.$store.commit('global/isMobileMenuOpen', !this.isMobileMenuOpen)
      await this.$nextTick()
      await this.$nextTick()

      this.$refs.closeButtonRef?.focus()
    },
    async closeMenu() {
      await this.$store.commit('global/isMobileMenuOpen', false)
      await this.$nextTick()
      await this.$nextTick()

      this.$refs.openButtonRef?.focus()
    },
  },
}
