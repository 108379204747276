//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CareCard from '~/components/blocks/CareCard'

export default {
  name: 'ThreeUpCard',
  components: {
    CareCard,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
}
