import { render, staticRenderFns } from "./ThreeUpCard.vue?vue&type=template&id=b0d482a8&scoped=true&"
import script from "./ThreeUpCard.vue?vue&type=script&lang=js&"
export * from "./ThreeUpCard.vue?vue&type=script&lang=js&"
import style0 from "./ThreeUpCard.vue?vue&type=style&index=0&id=b0d482a8&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0d482a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeading: require('/opt/build/repo/components/base/BaseHeading.vue').default})
