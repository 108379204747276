import { render, staticRenderFns } from "./BaseMenuItem.vue?vue&type=template&id=31b0359a&"
import script from "./BaseMenuItem.vue?vue&type=script&lang=js&"
export * from "./BaseMenuItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/opt/build/repo/components/base/BaseIcon.vue').default,BaseMenu: require('/opt/build/repo/components/base/BaseMenu.vue').default})
