import { render, staticRenderFns } from "./SiteHeader.vue?vue&type=template&id=417f78ad&"
import script from "./SiteHeader.vue?vue&type=script&lang=js&"
export * from "./SiteHeader.vue?vue&type=script&lang=js&"
import style0 from "./SiteHeader.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/opt/build/repo/components/base/BaseImage.vue').default,BaseLink: require('/opt/build/repo/components/base/BaseLink.vue').default,BaseMenu: require('/opt/build/repo/components/base/BaseMenu.vue').default,BaseIcon: require('/opt/build/repo/components/base/BaseIcon.vue').default})
