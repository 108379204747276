import { render, staticRenderFns } from "./ImageHighlight.vue?vue&type=template&id=9406af58&scoped=true&"
import script from "./ImageHighlight.vue?vue&type=script&lang=js&"
export * from "./ImageHighlight.vue?vue&type=script&lang=js&"
import style0 from "./ImageHighlight.vue?vue&type=style&index=0&id=9406af58&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9406af58",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/opt/build/repo/components/base/BaseImage.vue').default,BaseHeading: require('/opt/build/repo/components/base/BaseHeading.vue').default,BaseText: require('/opt/build/repo/components/base/BaseText.vue').default})
