import { render, staticRenderFns } from "./ClientTestimonial.vue?vue&type=template&id=31b030d0&"
import script from "./ClientTestimonial.vue?vue&type=script&lang=js&"
export * from "./ClientTestimonial.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/opt/build/repo/components/base/BaseIcon.vue').default,BaseHeading: require('/opt/build/repo/components/base/BaseHeading.vue').default,BaseText: require('/opt/build/repo/components/base/BaseText.vue').default})
