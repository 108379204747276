//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    theme: {
      type: String,
      default: 'primary',
    },
    href: {
      type: String,
      required: false,
    },
  },
}
