import { render, staticRenderFns } from "./CareCard.vue?vue&type=template&id=80fc7f66&scoped=true&"
import script from "./CareCard.vue?vue&type=script&lang=js&"
export * from "./CareCard.vue?vue&type=script&lang=js&"
import style0 from "./CareCard.vue?vue&type=style&index=0&id=80fc7f66&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80fc7f66",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/opt/build/repo/components/base/BaseImage.vue').default,BaseHeading: require('/opt/build/repo/components/base/BaseHeading.vue').default,BaseText: require('/opt/build/repo/components/base/BaseText.vue').default,BaseLink: require('/opt/build/repo/components/base/BaseLink.vue').default,BaseButton: require('/opt/build/repo/components/base/BaseButton.vue').default})
